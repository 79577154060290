import { apiQueryMetaTraderAccountDetails } from '@/resource'

export default {
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      hasLiveAccounts: false,
    }
  },
  mounted() {
    this.queryMetaTraderAccountDetails()
  },
  watch: {
    showSmartTrader: {
      handler() {
        this.$nextTick(() => {
          this.changeLiWidth()
        })
      },
    },
  },
  methods: {
    queryMetaTraderAccountDetails() {
      apiQueryMetaTraderAccountDetails({
        user_id: this.uuid,
      }).then(resp => {
        if (resp.data.length > 0) {
          this.hasLiveAccounts = true
        }
      })
    },
  },
}
