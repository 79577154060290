<template>
  <div id="downloads">
    <div class="content_box">
      <div>
        <h3>{{ $t('menu.download') }}</h3>
        <div class="meta_box">
          <div class="box mb-5" v-if="$platform.getTradingPlatform(regulator).mt4">
            <h4>{{ $t('downloads.mt4') }}</h4>
            <ul class="mt4_ul">
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt4.METATRADER4_windows">
                <div class="title">
                  <div class="icon windows"></div>
                  <p>{{ $t('downloads.windows') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture windows"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt4.METATRADER4_windows)"
                  data-testid="windows4"
                >
                  <div class="content">
                    <div class="icon windows"></div>
                    {{ $t('downloads.button.download') }}
                  </div>
                </el-button>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt4.METATRADER4_iOS">
                <div class="title">
                  <div class="icon mac"></div>
                  <p>{{ $t('downloads.mac') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture mac"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt4.METATRADER4_iOS)"
                  data-testid="mac4"
                >
                  <div class="content">
                    <div class="icon mac"></div>
                    {{ $t('downloads.button.download') }}
                  </div>
                </el-button>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt4.METATRADER4_iPhone">
                <div class="title">
                  <div class="icon iphone"></div>
                  <p>{{ $t('downloads.ip') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture iphone"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt4.METATRADER4_iPhone)"
                  data-testid="iphone4"
                >
                  <div class="content">
                    <div class="icon iphone"></div>
                    {{ $t('downloads.button.download') }}
                  </div>
                </el-button>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt4.METATRADER4_Android">
                <div class="title">
                  <div class="icon android"></div>
                  <p class="text-center">{{ $t('downloads.android') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture android"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt4.METATRADER4_Android)"
                  data-testid="android5"
                >
                  <div class="content">
                    <div class="icon android"></div>
                    {{ $t('downloads.button.download') }}
                  </div>
                </el-button>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt4.METATRADER4_WebTrader">
                <div class="title">
                  <div class="icon web_trader"></div>
                  <p>{{ $t('downloads.webTrader') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture web_trader"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt4.METATRADER4_WebTrader)"
                  data-testid="web4"
                >
                  {{ $t('downloads.button.launch') }}
                </el-button>
              </li>
            </ul>
          </div>
          <div class="box mb-5 pt-3" v-if="$platform.getTradingPlatform(regulator).mt5">
            <h4>{{ $t('downloads.mt5') }}</h4>
            <ul>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt5.METATRADER5_windows">
                <div class="title">
                  <div class="icon windows"></div>
                  <p>{{ $t('downloads.windows') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture windows"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt5.METATRADER5_windows)"
                  data-testid="windows5"
                >
                  <div class="content">
                    <div class="icon windows"></div>
                    {{ $t('downloads.button.download') }}
                  </div>
                </el-button>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt5.METATRADER5_iOS">
                <div class="title">
                  <div class="icon mac"></div>
                  <p>{{ $t('downloads.mac') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture mac"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt5.METATRADER5_iOS)"
                  data-testid="mac5"
                >
                  <div class="content">
                    <div class="icon mac"></div>
                    {{ $t('downloads.button.download') }}
                  </div>
                </el-button>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt5.METATRADER5_iPhone">
                <div class="title">
                  <div class="icon iphone"></div>
                  <p class="text-center">{{ $t('downloads.ip') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture iphone"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt5.METATRADER5_iPhone)"
                  data-testid="iphone5"
                >
                  <div class="content">
                    <div class="icon iphone"></div>
                    {{ $t('downloads.button.download') }}
                  </div>
                </el-button>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="METATRADER5_Android">
                <div class="title">
                  <div class="icon android"></div>
                  <p class="text-center">{{ $t('downloads.android') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture android"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(METATRADER5_Android)"
                  data-testid="android5"
                >
                  <div class="content">
                    <div class="icon android"></div>
                    {{ $t('downloads.button.download') }}
                  </div>
                </el-button>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4" v-if="urls.mt5.METATRADER5_WebTrader">
                <div class="title">
                  <div class="icon web_trader"></div>
                  <p>{{ $t('downloads.webTrader') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture web_trader"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.mt5.METATRADER5_WebTrader)"
                  data-testid="web4"
                >
                  {{ $t('downloads.button.launch') }}
                </el-button>
              </li>
            </ul>
          </div>
          <div class="box mb-5 pt-3" v-if="$platform.getTradingPlatform(regulator).appTrader">
            <h4>{{ $t('downloads.appTrader.title') }}</h4>
            <ul>
              <li class="me-3 ms-3 mt-4 pt-4 px-3 pb-4">
                <div class="title">
                  <p>{{ $t('downloads.appTrader.iosAndroid') }}</p>
                </div>
                <div class="pic_container app_trader">
                  <template v-if="urls.appTrader.APPTRADER_iOS">
                    <div class="ios">
                      <div class="picture"></div>
                      <a :href="urls.appTrader.APPTRADER_iOS" target="_blank" data-testid="appStore">
                        <div class="img"></div>
                      </a>
                    </div>
                  </template>
                  <template v-if="urls.appTrader.APPTRADER_Android">
                    <div class="android">
                      <div class="picture"></div>
                      <a :href="urls.appTrader.APPTRADER_Android" target="_blank" data-testid="playStore">
                        <div class="img"></div>
                      </a>
                    </div>
                  </template>
                </div>
              </li>
              <li class="me-3 ms-3 mt-4 pt-4 px-4 pb-4" v-if="urls.appTrader.PROTRADER">
                <div class="suffix"></div>
                <div class="title">
                  <div class="icon pro_trader"></div>
                  <p>{{ $t('downloads.proTrader') }}</p>
                </div>
                <div class="pic_container">
                  <div class="picture pro_trader"></div>
                </div>
                <el-button
                  class="btn py-2"
                  type="primary"
                  @click="clickDownload(urls.appTrader.PROTRADER)"
                  data-testid="proTrader"
                >
                  {{ $t('downloads.button.launch') }}
                </el-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vDialog from '@/components/home/vDialog';
import fcaConstraintsMixin from '@/mixins/page/downloads/fcaConstraints';
import { countryCodeEnum } from '@/constants/country';

export default {
  name: 'downloads',
  components: { vDialog },
  mixins: [fcaConstraintsMixin],
  computed: {
    urls() {
      return this.$platform.getMetaTraderLinks(this.regulator, this.GLOBAL_DOMAIN_WEBSITE);
    },
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    },
    METATRADER5_Android() {
      switch (this.countryCode) {
        case countryCodeEnum.CHINA:
          return this.urls?.mt5.METATRADER5_Android_China;
        default:
          return this.urls?.mt5.METATRADER5_Android;
      }
    }
  },
  methods: {
    clickDownload(url) {
      if (url) window.open(url);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/downloads.scss';
</style>
